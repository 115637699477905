import { Query, queryOptions, useQuery } from "@tanstack/react-query";
import type { GetNetworkRequest, Network } from "../../apis/api-types";
import { conduitAPI } from "../../apis/conduit-api";
import { isCelestia, isEigenDa } from "../../utils/metadata";
import { isEmpty } from "remeda";
import { useOrganization } from "../../context/hooks";

export function useGetNetwork(
  slugOrId: string | undefined,
  /**
   * @todo make this better
   */
  options: {
    retry?: boolean;
    refetchInterval?:
      | number
      | false
      | ((
          query: Query<
            Network,
            Error,
            Network,
            readonly ["GetNetwork", string, string | undefined]
          >,
        ) => number | false | undefined);
  } = {},
) {
  const organization = useOrganization();

  return useQuery({
    enabled: !isEmpty(organization as Record<string, never>) && !!slugOrId,
    ...options,
    queryKey: ["GetNetwork", organization.organization, slugOrId] as const,
    queryFn: ({ signal }) =>
      patchGetNetwork(
        { organization: organization.organization, network: slugOrId! },
        signal,
      ),
  });
}

export function getNetwork({
  organization,
  network,
}: {
  organization: string;
  network: string;
}) {
  return queryOptions({
    queryKey: ["GetNetwork", organization, network] as const,
    queryFn: ({ signal }) => patchGetNetwork({ organization, network }, signal),
    refetchOnWindowFocus: false,
  });
}

async function patchGetNetwork(args: GetNetworkRequest, signal?: AbortSignal) {
  const resp = await conduitAPI.getNetwork(args, signal);

  const information = resp.network.information.filter(
    (el) => !["Settlement Layer", "Data Availability Layer"].includes(el.name),
  );

  information.splice(
    2,
    0,
    {
      name: "Framework",
      value: resp.network.type,
      url: "",
      network: resp.network.network,
    },
    {
      name: "Settlement layer",
      value: resp.network.type,
      url: "",
      network: resp.network.network,
    },
    {
      name: "Data availability layer",
      value: resp.network.type,
      url: "",
      network: resp.network.network,
    },
    {
      name: "Environment",
      value: resp.network.type,
      url: "",
      network: resp.network.network,
    },
  );

  const urls = resp.network.urls.map((el) => {
    if (
      el.displayName === "AnyTrust" &&
      (isCelestia(resp.network.type) || isEigenDa(resp.network.type))
    ) {
      return { ...el, displayName: "AnyTrust (Fallback)" };
    }

    return el;
  });

  return {
    ...resp.network,
    information,
    urls,
  } as Network;
}
